import { BreakpointObserver } from '@angular/cdk/layout';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@root/environments/environment';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

type AppFetchingModel = {
  windowsToken: boolean;
  userData: boolean;
  login: boolean;
  dailyReportDetail: boolean;
  dailyReportStudy: boolean;
  permitPrint: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class AppService implements OnDestroy {
  private breakpointObserver = inject(BreakpointObserver);
  isProd: boolean = environment.production;
  isXsmallScreen = false;
  isSmallScreen = false;
  isMeduimScreen = false;
  isLargeScreen = false;
  isMobileOrTablet = false;
  public isLoading = new BehaviorSubject(false);
  destroyed = new Subject<void>();

  get loading() {
    return this.isLoading.value;
  }

  private _fetching: AppFetchingModel = {
    windowsToken: false,
    userData: false,
    login: false,
    dailyReportDetail: false,
    dailyReportStudy: false,
    permitPrint: false,
  };

  get fetchingState() {
    return this._fetching;
  }

  constructor() {
    this.init();
  }

  init(): void {
    this.breakpointObserver
      .observe('(max-width: 640px)')
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        this.isXsmallScreen = result.matches;
      });
    this.breakpointObserver
      .observe('(max-width: 900px)')
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        this.isSmallScreen = result.matches;
      });
    this.breakpointObserver
      .observe('(max-width: 1366px)')
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        this.isMeduimScreen = result.matches;
      });
    this.breakpointObserver
      .observe('(min-width: 2000px)')
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        this.isLargeScreen = result.matches;
      });

    const ua = navigator.userAgent;
    this.isMobileOrTablet =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      );
  }

  updateFetchingState(model: Partial<AppFetchingModel>) {
    this._fetching = {
      ...this._fetching,
      ...model,
    };
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
